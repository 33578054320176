import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Request from 'axios';
import { Field, Form, Formik } from 'formik';
import InputField from './InputField';
import SelectField from './SelectField';
import { API_URL, DIRECTIONS } from '../settings';

import './style.scss';

const EMAIL_VALID_PATERN = /^[\w-.]+@[\w-]+\.[a-z]+$/i;

export default class RecordModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            errorMsg: '',
            successMsg: '',
        };
    }

    trySave = (record) => {
        const prepareData = {
            ...record,
            contactPhone: record.contactPhone && record.contactPhone.replace(/[_,(,),\s]/gi, ''),
            studentBirthday:
                record.studentBirthday && !record.studentBirthday.includes('_')
                    ? new Date(record.studentBirthday.split('.').reverse().join('-'))
                    : null,
            direction: record.direction.value,
        };

        Request.put(`${API_URL}/records`, prepareData)
            .then(({ data }) => {
                if (data.success) {
                    this.setState({
                        success: true,
                        successMsg: data.msg,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    success: false,
                    errorMsg: 'Ошибка отправки данных',
                });
            });
    };

    onClose = () => {
        const { close } = this.props;
        this.setState({ success: false, errorMsg: '', successMsg: '' });
        close();
    };

    render() {
        const { isOpen } = this.props;
        const { success, successMsg, errorMsg } = this.state;

        return (
            <Modal visible={isOpen} width="540" effect="fadeInUp" onClickAway={() => this.onClose()}>
                {!success ? (
                    <div className="BJJ_ModalContent">
                        <div className="BJJ_ModalClose" onClick={() => this.onClose()} />
                        <span className="BJJ_ModalTitle">{'Заполните форму'}</span>
                        <Formik
                            initialValues={{
                                contactFio: '',
                                studentFio: '',
                                contactPhone: '',
                                contactEmail: '',
                                studentBirthday: '',
                                studyPlace: '',
                                direction: { value: DIRECTIONS[0], label: DIRECTIONS[0] },
                            }}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                this.trySave(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <div className="BJJ_FieldRow">
                                        <Field
                                            required
                                            name="contactFio"
                                            placeholder="ФИО"
                                            label="ФИО контактного лица"
                                            component={InputField}
                                            validate={(val) => (val ? '' : 'required')}
                                        />
                                    </div>
                                    <div className="BJJ_FieldRow">
                                        <Field
                                            required
                                            name="contactEmail"
                                            placeholder="Email"
                                            label="Почта"
                                            component={InputField}
                                            validate={(val) => (EMAIL_VALID_PATERN.test(val) ? '' : 'wrong email')}
                                        />

                                        <Field
                                            required
                                            mask="+7(999) 999 99 99"
                                            name="contactPhone"
                                            label="Телефон"
                                            placeholder="+7(999) 999 99 99"
                                            component={InputField}
                                            validate={(val) =>
                                                val.replace(/[_,(,),\s]/gi, '').length === 12 ? '' : 'wrong'
                                            }
                                        />
                                    </div>

                                    <div className="BJJ_FieldRow">
                                        <Field
                                            required
                                            name="studentFio"
                                            placeholder="ФИО"
                                            label="ФИО занимающегося"
                                            component={InputField}
                                            validate={(val) => (val ? '' : 'required')}
                                        />

                                        <Field
                                            required
                                            name="studentBirthday"
                                            mask="99.99.9999"
                                            label="Дата рождения"
                                            placeholder="99.99.9999"
                                            component={InputField}
                                            validate={(val) => (!val || val.includes('_') ? 'wrong birthday' : '')}
                                        />
                                    </div>
                                    <div className="BJJ_FieldRow">
                                        <Field
                                            required
                                            name="studyPlace"
                                            placeholder="Место учебы"
                                            label="Место учебы"
                                            component={InputField}
                                            validate={(val) => (val ? '' : 'required')}
                                        />
                                        <Field
                                            required
                                            name="direction"
                                            placeholder="Направление"
                                            label="Направление"
                                            component={SelectField}
                                            options={DIRECTIONS.map((item) => ({ label: item, value: item }))}
                                            validate={(val) => (val ? '' : 'required')}
                                        />
                                    </div>
                                    <div className="BJJ_FieldRow">
                                        <Field
                                            name="studentNote"
                                            placeholder="..."
                                            label="Информация, о которой важно знать тренеру"
                                            component={InputField}
                                        />
                                    </div>

                                    {errorMsg && <div className="BJJ_Error">{errorMsg}</div>}

                                    <button type="submit" className="BJJ_Button">
                                        Отправить
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    <div className="BJJ_ModalContent">
                        <div className="BJJ_ModalClose" onClick={() => this.onClose()} />
                        <span className="BJJ_ModalTitle">{'Заявка успешно принята!'}</span>
                        <div>{successMsg}</div>
                    </div>
                )}
            </Modal>
        );
    }
}

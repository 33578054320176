import React from 'react';
import InputMask from 'react-input-mask';

const InputField = ({ field, form, ...props }) => (
    <div className={`BJJ_Field ${props.required ? '__required' : ''}`}>
        <label className="BJJ_Label">{props.label}</label>
        <InputMask
            className={`BJJ_Input ${form.errors[field.name] && form.touched[field.name] ? 'BJJ_InputError' : ''}`}
            {...field}
            {...props}
        />
        <div className="BJJ_Error"></div>
    </div>
);

export default InputField;

import React from 'react';
import Select from 'react-select';

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        padding: 10,
    }),
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
};

const SelectField = ({ field, form, ...props }) => (
    <div className={`BJJ_Field ${props.required ? '__required' : ''}`}>
        <label className="BJJ_Label">{props.label}</label>
        <Select
            {...field}
            {...props}
            styles={colourStyles}
            className={`${form.errors[field.name] && form.touched[field.name] ? 'BJJ_InputError' : ''}`}
            onChange={(val) => form.setFieldValue('direction', val)}
            theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                colors: {
                    ...theme.colors,
                    primary: '#00a64d',
                },
            })}
        />
        <div className="BJJ_Error"></div>
    </div>
);

export default SelectField;

import React, { PureComponent } from 'react';
import moment from 'moment';
import PubSub from './PubSub';
import { API_URL, V } from './settings';
import AuthModal from './AuthModal';
import RecordModal from './RecordModal';
import 'moment/locale/ru';
import './App.scss';

moment.locale('ru');
class App extends PureComponent {
    constructor() {
        super();
        this.state = {
            isAuthModalOpen: false,
            isRecordModalOpen: false,
        };
        window.BJJ = {
            PubSub,
            v: V,
            apiUrl: API_URL,
        };
    }

    componentDidMount() {
        PubSub.on('AUTH', this.showAuthModal);
        PubSub.on('RECORD', this.showRecordModal);
    }

    componentWillUnmount() {
        PubSub.off('AUTH', this.showAuthModal);
        PubSub.off('RECORD', this.showRecordModal);
    }

    showAuthModal = () => {
        this.setState({
            isAuthModalOpen: true,
        });
    };

    showRecordModal = () => {
        this.setState({
            isRecordModalOpen: true,
        });
    };

    render() {
        const { isAuthModalOpen, isRecordModalOpen } = this.state;

        return (
            <React.Fragment>
                <AuthModal isOpen={isAuthModalOpen} close={() => this.setState({ isAuthModalOpen: false })} />
                <RecordModal isOpen={isRecordModalOpen} close={() => this.setState({ isRecordModalOpen: false })} />
            </React.Fragment>
        );
    }
}

export default App;

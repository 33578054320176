import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

const Botstrap: React.FC = () => {
    return (
        <div>
            <App />
        </div>
    );
};

ReactDOM.render(<Botstrap />, document.getElementById('BJJ_WIDGET'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

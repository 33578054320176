import React, { Component } from 'react';
import Modal from 'react-awesome-modal';
import Request from 'axios';
import { API_URL, LK_URL } from '../settings';

import './style.scss';

export default class AuthModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            remeberMode: false,
            successSendLeter: false,
        };
    }

    tryAuth = () => {
        const { email, password } = this.state;

        Request.post(`${API_URL}/login`, {
            email,
            password,
        })
            .then(({ data }) => {
                sessionStorage.token = data.token;
                window.location.href = LK_URL;
            })
            .catch(() => {
                this.setState({
                    error: 'Не верный логин или пароль',
                });
            });
    };

    tryRemember = () => {
        const { email, successSendLeter } = this.state;
        if (successSendLeter) return;

        Request.post(`${API_URL}/user/restore`, {
            email,
        })
            .then(({ data }) => {
                if (data.success) {
                    this.setState({ successSendLeter: true, error: '' });
                } else {
                    this.setState({
                        error: data.msg,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error: 'Ошибка отправки письма',
                });
            });
    };

    onClose = () => {
        const { close } = this.props;
        this.setState({ remeberMode: false, error: '' });
        close();
    };

    render() {
        const { isOpen } = this.props;
        const { email, password, error, remeberMode, successSendLeter } = this.state;
        return (
            <Modal visible={isOpen} width="340" effect="fadeInUp" onClickAway={() => this.onClose()}>
                <div className="BJJ_ModalContent">
                    <div className="BJJ_ModalClose" onClick={() => this.onClose()} />
                    <span className="BJJ_ModalTitle">
                        {!remeberMode ? 'Вход в личный кабинет' : 'Восстановление пароля'}
                    </span>

                    <div className="BJJ_Field">
                        <label className="BJJ_Label">Email</label>
                        <input
                            name="email"
                            className="BJJ_Input"
                            value={email}
                            onChange={(e) => {
                                this.setState({
                                    email: e.target.value,
                                });
                            }}
                        />
                    </div>

                    {!remeberMode && (
                        <div className="BJJ_Field">
                            <label className="BJJ_Label">Пароль</label>
                            <input
                                name="password"
                                type="password"
                                className="BJJ_Input"
                                value={password}
                                onChange={(e) => {
                                    this.setState({
                                        password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    )}

                    {successSendLeter && <p>Письмо успешно отправленно</p>}

                    {error && <div className="BJJ_Error">{error}</div>}

                    <button className="BJJ_Button" onClick={() => (!remeberMode ? this.tryAuth() : this.tryRemember())}>
                        {remeberMode ? 'Отправить письмо' : 'Войти'}
                    </button>

                    <div
                        className="BJJ_AuthModalLink BJJ_AuthModalLinkRemember"
                        onClick={() =>
                            this.setState({
                                remeberMode: !remeberMode,
                                error: '',
                                successSendLeter: false,
                            })
                        }
                    >
                        {!remeberMode ? 'Я не помню пароль' : 'Назад'}
                    </div>
                </div>
            </Modal>
        );
    }
}

const PubSub = {
    _eventHandlers: {},

    on: function on(eventName, handler) {
        if (!eventName || !handler) {
            return;
        }

        if (typeof this._eventHandlers[eventName] === 'undefined') {
            this._eventHandlers[eventName] = [handler];
            return this;
        }

        this._eventHandlers[eventName].push(handler);

        return this;
    },

    off: function off(eventName, handler) {
        if (!eventName || !handler) {
            return;
        }
        const handlers = this._eventHandlers && this._eventHandlers[eventName];

        if (!handlers) {
            return;
        }

        handlers.forEach((element, key) => {
            if (element === handler || element.name === handler.name) {
                handlers.splice(key, key + 1);
            }
        });
    },

    trigger: function trigger(eventName) {
        if (!this._eventHandlers || !this._eventHandlers[eventName]) {
            return this;
        }

        const _arguments = arguments;

        this._eventHandlers[eventName].forEach((handler) => {
            handler(...[].slice.call(_arguments, 1));
        });

        return this;
    },

    send: function send(eventName, data) {
        if (!this._eventHandlers || !this._eventHandlers[eventName]) {
            return Promise.reject(new Error(`Empty handlers for this event ${eventName} `));
        }
        const promises = this._eventHandlers[eventName].map(
            (func) =>
                new Promise((resolve, reject) => {
                    try {
                        if (func.length === 2) {
                            func(data, resolve);
                        } else {
                            resolve(data);
                        }
                    } catch (err) {
                        reject(err);
                    }
                }),
        );
        return Promise.all(promises);
    },
};

export default PubSub;
